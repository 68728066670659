<template>
  <div class="flix-row">
    <div class="flix-col-md-12">
      <div class="flix-form-group">
        <h3 class="flix-html-h3">{{ $tc('message.contact', 1) }} ({{ $t('message.public') }})</h3>
      </div>
    </div>
    <div class="flix-col-md-6">
      <div class="flix-form-group">
        <label class="flix-html-label" for="name">{{ $t('message.contactPerson') }}</label>
      </div>
      <div class="flix-form-group">
        <input type="text" spellcheck="false" @change="changeContact" v-model="contactName" class="flix-form-control" id="name" :placeholder="$t('message.first_name')+' '+$t('message.last_name')">
      </div>
    </div>
    <div class="flix-col-md-6">
      <div class="flix-form-group">
        <label class="flix-html-label" for="email">{{ $t('message.contactEmail') }}</label>
      </div>
      <div class="flix-form-group">
        <input type="email" spellcheck="false" @change="changeContact" v-model="contactEmail" class="flix-form-control" id="email" :placeholder="$t('message.email')">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: { data: Object, callback: Function },
  data () {
    return {
      contactName: this.data.contactName,
      contactEmail: this.data.contactEmail
    }
  },
  methods: {
    changeContact () {
      this.callback({
        contactName: this.contactName,
        contactEmail: this.contactEmail
      })
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
